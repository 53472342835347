body * {
  font-family: "Inter", sans-serif;
}

.content-wrapper .content .card {
  box-shadow: none !important;
  border: none;
  margin: 0 16px 12px;
  background: transparent;
}

.content-wrapper .content .card .card-body {
  background: #ffffff;
  border: 1px solid #edeef1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
}

.content-wrapper .content .card .card-body .column-flex {
  text-align: left;
  width: 100%;
}

.content-wrapper .content .card .card-body .column-flex h4.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 0;
}

.content-wrapper .content .card .card-body .column-flex select.form-control {
  width: auto;
  background-image: none;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  height: auto;
  appearance: auto;
  cursor: pointer;
}

.content-wrapper .content .card .card-body .item .btn {
  margin-left: 8px;
}

.btn.btn-secondary {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc !important;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #222222;
  text-transform: capitalize;
  min-width: 72px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(0, 0, 0, 0.06) 100%
  );
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-primary:disabled,
.btn.btn-secondary:disabled {
  cursor: default;
}

.btn.btn-primary {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  min-width: 72px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 28.65%,
    rgba(0, 0, 0, 0.15) 100%
  );
  /* background-color: #0077BE; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card.card-content .card-body > .row {
  align-items: center;
}

.card.card-content .card-body > .row .col-6:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
  /* border-style: solid; */
  border-width: 1px;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #222222;
  text-transform: capitalize;
  min-width: 72px;
  /* background: linear-gradient( 180deg, rgba(255, 255, 255, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%); */
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-primary {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff !important;
  text-transform: none;
  min-width: 72px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 28.65%,
    rgba(0, 0, 0, 0.15) 100%
  );
  /* background-color: #0077BE; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-sec {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #222 !important;
  text-transform: capitalize;
  min-width: 72px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 28.65%,
    rgba(0, 0, 0, 0.15) 100%
  );
  /* background-color: #0077BE; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card.card-content .card-body > .row {
  align-items: center;
}

.card.card-content .card-body > .row .col-6:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-wrapper .content .card.card-table .card-body {
  padding: 0;
}

.card.card-table .card-body .table-section {
  margin-top: 8px;
}

.card.card-table .card-body .table-section table {
  border: none;
}

.card.card-table .card-body .table-section table thead tr th {
  /* background: #f0f7fb; */
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  border-left: 1px solid #fff;
  text-align: left;
  border-bottom: none;
  text-transform: capitalize;
}

.card.card-table .card-body .table-section table tbody tr:nth-child(2n) {
  background: #f8fafb;
}

.card.card-table .card-body .table-section table tbody tr td {
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 24px;
  color: #222222 !important;
  padding: 4px 10px;
  border-left: 1px solid #edf0f4;
  border-bottom: 1px solid #edf0f4;
  text-align: left;
}

.card.card-table .card-body .table-section .MuiPaper-elevation2 {
  box-shadow: none;
  border-radius: 0;
}

.card.card-table .card-body .table-section .MuiToolbar-regular {
  min-height: initial !important;
  padding: 0 16px;
  margin-bottom: 8px;
  /* display: none; */
}

.card.card-table .card-body .table-section .MuiToolbar-regular h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 0;
}

.card.card-table .card-body .table-section table thead tr th:last-child {
  border-right: 1px solid #edf0f4;
}

.card.card-table .card-body .table-section table thead tr th:first-child {
  border-left: 1px solid #edf0f4;
}

.card.card-table .card-body .table-section table tbody tr td:last-child {
  border-right: 1px solid #edf0f4;
}

.card.card-table .card-body .table-section table tbody tr:nth-child(2n + 1) {
  background: #fff;
}

.card.card-table .card-body .table-section table thead tr th > div > svg {
  position: absolute;
  right: -10px;
  color: #fff !important;
  z-index: 99999;
}

.content-wrapper .content .card.card-details-head {
  margin: 0 0 12px;
}

.content-wrapper .content .card.card-details-head .card-body {
  padding: 0;
}

.card.card-details-head .d-flex.head-details {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 18px 16px;
  background: #fafafa;
  /* border-bottom: 1px solid #edeef1; */
  border-radius: 8px;
}

.card.card-details-head .card-header.tab-list {
  padding: 0;
  border-bottom: none;
}

.card.card-details-head .d-flex.head-details .flex-box-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
}

.flex-box-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card.card-details-head .d-flex.head-details .flex-box-left .img-box {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
}

.card.card-details-head .d-flex.head-details .flex-box-left .details p {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card.card-details-head .d-flex.head-details .flex-box-left .details .sub {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  margin: 0;
}

.tag.blue {
  color: #0077be;
  border-style: solid;
  border-width: 1px;
  border-color: #0077be;
}

.card.card-details-head .d-flex.head-details .flex-box-left .details .tag {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border-radius: 4px;
  padding: 0 4px;
}

.content-wrapper .content .card.card-details-head + .card.card-table {
  margin: 0 0 12px;
}

.flex-box-right .item.flex-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-box-right .item.flex-btn .dropdown .btn.btn-primary {
  color: #fff;
  margin: 0;
  border-radius: 0 6px 6px 0;
  border-left: 1px solid #fff !important;
  padding: 0 5px;
}

.flex-box-right .item.flex-btn > .btn.btn-primary {
  border-radius: 6px 0 0 6px;
}

.flex-box-right .dropdown .icon {
  line-height: 15px;
  font-size: 20px;
  position: relative;
  top: 0;
}

.flex-box-right .dropdown .btn.btn-secondary {
  padding: 0 5px;
}

.right-card-content {
  background: #fff;
  border-top: 1px solid #ebebeb;
  border-radius: 0 0 8px 8px;
  padding: 8px 16px;
  flex: 1;
}

.right-card-content .card-body {
  padding: 0;
}

.right-card-content .card-body ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

.right-card-content .card-body ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  margin: 12px 0;
  background: #f0f7fb;
  border-radius: 8px;
}

.right-card-content .card-body ul li .icon {
  color: #0091ea;
  margin-right: 14px;
}

.right-card-content .card-body ul li .item .text {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 8px;
}

.right-card-content .card-body ul li .item .sub {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.activity-card .card-header.tab-list {
  border-radius: 8px 8px 0 0;
}

.right-card-content .card-body ul li.grey {
  background: #edf0f4;
}

.boxed-wrapper > .row {
  align-items: stretch;
  width: 100%;
}

.boxed-wrapper > .row .activity-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.content-wrapper .content .card:last-child {
  /* margin-bottom: 0 !important; */
}

.boxed-wrapper > .row .col-md-7.col-xs-12 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  /* min-height: calc(100vh - 116px);
    max-height: calc(100vh - 116px); */
}

.boxed-wrapper > .row .col-md-7.col-xs-12 > .card.card-accordion {
  flex: 1;
  margin-bottom: 12px !important;
}

.boxed-wrapper > .row .col-md-7.col-xs-12 .card.card-table {
  flex: 1;
  margin-bottom: 12px;
}

.table-action-icon-header {
  color: #222222 !important;
  font-size: 18px !important;
}

.table-action-icons {
  color: #222222 !important;
  font-size: 13px !important;
}

.header-action-icons {
  color: #222222 !important;
  font-size: 13px !important;
}

.icon-padding {
  padding: 0px 5px;
}

.primary-button-icon {
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 0px 6px 0 0;
}

.common-text {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  line-height: 1.6;
  color: #555555;
}

.common-big-text {
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  line-height: 20px;
  color: #222222;
}

.tab-container {
  margin: 0 16px 12px;
  background: white;
  border-radius: 4px;
  padding-bottom: 5px;
}

.tabrow {
  padding: 15px 0px;
  display: flex;
}

.to-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px 15px !important;
}

.buttonContainer {
  /* right: 15px;
    top: 5px; */
}

.subhead-container {
  text-align: left;
  padding: 0px 15px;
}

.sub-cont {
  padding: 0px 15px;
  text-align: left;
}

.subhead {
  font-size: 14px;
  font-weight: 700;
}

.treeitemContainer {
  display: flex;
}

.closeButtonStyle {
  float: right;
  padding: 0px !important;
}

.headerContents {
  display: flex !important;
  padding: 8px 0px;
  margin: 0;
  border-color: #edeef1;
  border-width: 1px 0 1px 0;
  border-style: solid;
  align-items: center;
  justify-content: flex-start;
}

.headerContents .col-4 {
  padding: 0 16px;
}

.seach-con {
  background: white;
  border: 1px solid #e0e2e7;
  border-radius: 4px;
  margin: 0px 8px 0px 0px;
  height: 32px;
}

.nobg {
  background: none;
}

.dialogHeader {
  font-weight: bold;
  font-size: 16px;
  line-height: 2.4;
  text-align: left;
}

.inp-margin {
  margin: 0px 10px 0px 10px !important;
  border: none !important;
}

.content-wrapper .content .card.card-accordion {
  width: 100%;
  margin: 0;
}

.content-wrapper .content .card.card-accordion .card-body {
  padding: 0;
  /* overflow: hidden; */
}

.card.card-accordion .accordion .accordion-item .accordion-header {
  background: #f8fafb;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 12px;
  border-radius: 8px;
}

.card.card-accordion .accordion .accordion-item .accordion-body {
  padding: 16px 16px 2px;
  /* max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden; */
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  width: 100%;
  padding: 6px 24px;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button.disabled:before {
  background: transparent;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:before {
  content: "";
  position: absolute;
  right: 24px;
  top: 16px;
  background: #222222;
  height: 1px;
  width: 10px;
}

.card.card-accordion
  .accordion
  .accordion-item:nth-child(1)
  .accordion-header:last-child {
  border-radius: 0 0 8px 8px;
  padding: 20px 16px 8px;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  width: 100%;
  padding: 10px 16px;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button.disabled:before {
  background: transparent;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:before {
  content: "";
  position: absolute;
  right: 24px;
  top: 20px;
  background: #222222;
  height: 1px;
  width: 10px;
}

.card.card-accordion
  .accordion
  .accordion-item:nth-child(1)
  .accordion-header:last-child {
  border-radius: 0 0 8px 8px;
}

.card.card-accordion .accordion .accordion-item .accordion-body .fieldset {
  /* flex: 0 0 380px; */
  max-width: 100%;
  width: 380px;
  padding: 0 14px 14px;
}

.card.card-accordion .accordion .accordion-item .accordion-body .row {
  margin: 0 -14px;
}

.fieldset .control-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #555555;
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
}

.fieldset .input-group .form-control {
  /* background: #fafafa; */
  border: 1px solid #edeef1;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 13px;
  line-height: 32px;
  color: #222222;
  padding: 0 8px;
  transition: all 0.3s ease;
  background-image: none;
  /* padding-right: 30px; */
}

.fieldset .input-group.mandatory .form-control {
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.fieldset .input-group .form-control::placeholder {
  color: #888888;
  font-size: 13px;
}

.card.card-table .card-body .table-section .MuiToolbar-regular .form-control {
  /* min-width: 300px; */
  height: auto;
  background: #ffffff;
  border: 1px solid #e0e2e7;
  border-radius: 4px;
  appearance: auto;
  min-height: 32px;
}

.fieldset .input-group .form-control {
  height: auto;
  /* cursor: pointer; */
  padding-right: 26px;
}

.card.card-table .card-body .table-section table tbody tr td a {
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 24px;
  color: #222222 !important;
}

.card.card-table .card-body .table-section table tbody tr td a {
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 24px;
  color: #222222 !important;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 408px;
  max-width: 800px;
  margin: 0;
  overflow: hidden;
}
.twocolumnwidth .MuiDialog-container .MuiPaper-elevation24 {
  width: 800px;
  max-width: calc(100% - 120px);
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .MuiTypography-h6 {
  padding: 12px 0;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form {
  margin: 0;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form > div {
  padding: 16px;
  /* max-height: 480px; */
  max-height: calc(100vh - 168px);
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  > .row {
  justify-content: center;
  position: relative;
  margin: 0;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  > .row
  .col-10 {
  padding: 0;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  > .row
  .col-2 {
  padding: 0;
  position: absolute;
  right: -4px;
  top: 0;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  .dialogHeader {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #222222;
  margin: 0;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  button.closeButtonStyle {
  font-size: 18px;
  background: transparent;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  button.closeButtonStyle
  svg {
  font-size: 18px;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form > div .fieldset {
  margin-bottom: 8px;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form > div .item {
  width: auto;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  form
  > div
  .item
  .btn.btn-primary {
  width: 100%;
  /* height: 40px; */
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  form
  > div.MuiDialogActions-spacing {
  padding: 0 24px 16px;
}

.card.card-table .card-body .table-section table tfoot .MuiToolbar-regular {
  margin: 0;
  width: 100%;
  height: 42px;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .MuiTablePagination-input {
  position: absolute;
  right: 16px;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  > div:last-child {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  > div:last-child
  button {
  color: #222;
  padding: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  border-radius: 6px;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .MuiTablePagination-input
  .MuiSelect-select.MuiSelect-select {
  background: #ffffff;
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px !important;
  font-weight: normal;
  font-size: 13px;
  color: #222222;
  min-width: 90px;
}
/* .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background: #ffffff;
  border: 1px solid #e0e2e7 !important;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px !important;
  font-weight: normal;
  font-size: 13px;
  color: #222222;
  min-width: 90px;
}
.css-1pa58rh-MuiInputBase-root-MuiInput-root {
  height: 32px !important;
}
.css-1pa58rh-MuiInputBase-root-MuiInput-root.Mui-disabled {
  background: #fafafa;
}
.css-1pa58rh-MuiInputBase-root-MuiInput-root.Mui-error {
  border: 1px solid red !important;
  border-radius: 4px;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root.Mui-disabled {
  background: #fafafa;
} */
.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  background: #ffff !important;
  border: none !important;
}
.css-1k430x0-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  margin:0px
}
.css-ei7t0p-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  font-size: 13px;
  padding: 4px !important;

  height: 24px;
}
/* .card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .MuiTablePagination-input
  .MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  background: #ffffff;
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px !important;
  font-weight: normal;
  font-size: 13px;
  color: #222222;
  min-width: 90px;
} */
.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .MuiTablePagination-input
  .MuiSelect-select.MuiSelect-select
  > div {
  display: flex;
  align-items: flex-start;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  div:last-child
  button
  .MuiIcon-root {
  font-size: 16px;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  div:last-child
  .MuiTypography-root.MuiTypography-caption {
  flex: unset !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  > div:last-child
  > span {
  margin: 0 4px;
  cursor: pointer;
}

.MuiPopover-paper ul.MuiList-root.MuiMenu-list {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
}

.MuiPopover-paper ul.MuiList-root.MuiMenu-list li.MuiListItem-button {
  font-size: 12.5px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #222222;
}

.card.card-table .card-body .table-section table thead tr th .MuiCheckbox-root {
  padding: 0;
}

.content-wrapper .content .card .card-body .item button.btn .icon {
  font-size: 13px;
  position: relative;
  top: 1px;
  line-height: initial;
  margin-right: 6px;
}

.card.card-table
  .card-body
  .table-section
  table
  thead
  tr
  th
  .MuiCheckbox-root
  span.MuiIconButton-label
  svg.MuiSvgIcon-root {
  font-size: 15px;
}

.card.card-table
  .card-body
  .table-section
  table
  tbody
  tr
  td
  .MuiCheckbox-colorSecondary {
  padding: 0 2px;
}

.card.card-table
  .card-body
  .table-section
  table
  tbody
  tr
  td
  .MuiCheckbox-colorSecondary
  .MuiSvgIcon-root {
  font-size: 15px;
}

.fieldset .input-group select.form-control {
  height: 34px;
  appearance: auto;
}

.card.card-table .card-body .table-section .MuiToolbar-regular .fieldset {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 8px;
  margin-bottom: 8px;
}

.card.card-table
  .card-body
  .table-section
  .MuiToolbar-regular
  .fieldset
  label.control-label {
  margin: 0;
  min-width: 100px;
}

.empty-content {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty-content .fullwidth .sub {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 16px;
}

.card.card-accordion.usergroup .accordion .accordion-item .accordion-body {
  padding: 8px 0;
}

.group-listing {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.group-listing .left-block {
  max-width: 30%;
  flex: 0 0 30%;
}

.group-listing .right-block {
  max-width: 70%;
  flex: 0 0 70%;
}

.group-listing .left-block .usergroup-list {
  padding: 0 16px;
  margin: 0;
  text-align: left;
  border-right: 1px solid #ebebeb;
  height: 100%;
}

.group-listing .left-block .usergroup-list li {
  list-style: none;
  margin-bottom: 4px;
}

.group-listing .left-block .usergroup-list li a {
  width: 100%;
  display: block;
  padding: 4px;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #222222;
  text-decoration: none;
  transition: all 0.2s ease;
}

.group-listing .right-block .usergroup-table {
  height: 100%;
  padding: 0 16px;
}

.group-listing .right-block .usergroup-table .title {
  padding: 6px 10px;
  text-align: left;
  background: #f8fafb;
}

.group-listing .right-block .usergroup-table .title h4 {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #222222;
  margin: 0;
}

.group-listing .left-block .usergroup-list li.active a {
  transition: all 0.2s ease;
  border-radius: 4px;
}

.content-wrapper .content .card.card-details .card-body {
  padding: 0 !important;
  border-radius: 8px 8px 0 0;
}

.content-wrapper .content .card.card-details .card-table .card-body {
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.content-wrapper .content .card.card-details .card-body > .row {
  align-items: center;
}

.content-wrapper .content .card.card-details .card-body .buttonContainer .item {
  padding-right: 16px;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiTypography-h6
  > .row
  .col-12 {
  padding: 0 16px;
}

.headerContents .seach-con {
  margin: 0;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.eq-padding {
  padding: 8px 16px 16px !important;
}

fieldset.input-group .form-control {
  color: #222;
  font-size: 13px;
  font-weight: normal;
}

fieldset.input-group .form-control::placeholder {
  font-weight: normal;
  font-size: 13px;
  color: #888888;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  tfoot.MuiTableFooter-root
  .MuiToolbar-regular {
  padding: 0 !important;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  tfoot.MuiTableFooter-root
  .MuiToolbar-regular
  .MuiTablePagination-input {
  left: 0 !important;
}

.fullwidth {
  width: calc(100% - 20px);
  margin: 30px 10px !important;
}

.card.card-table
  .card-body
  .table-section
  .MuiToolbar-regular
  .btn.btn-secondary
  svg {
  position: relative;
  top: 1px;
}

div.icon button svg {
  position: relative;
  top: 1px;
  font-size: 13px;
}

.content-wrapper .content .card.card-groupdetails .row {
  align-items: center;
}

.common-big-text.text-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.content-wrapper .content .card.card-groupdetails .card-body {
  border-radius: 0;
}

.content-wrapper .content .card.card-groupdetails .card-body:first-child {
  border-radius: 8px 8px 0 0;
}

.content-wrapper .content .card.card-groupdetails .card-body:last-child {
  border-radius: 0 0 8px 8px;
}

.content-wrapper .content .card.card-groupdetails .card-body.flex-details {
  background: #f8fafb;
}

.card-body.flex-details .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.card-body.flex-details .item .common-link-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 4px;
}

.card-body.flex-details .item:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  width: 1px;
  background: #b6b6b6;
}

.card-body.flex-details .item:last-child:before {
  background: transparent;
}

.content-wrapper .content .card.card-details-head .card-body > .row {
  align-items: center;
  width: auto;
}

.content-wrapper
  .content
  .card.card-details-head
  .card-body
  > .row
  .buttonContainer {
  padding: 0;
}

.content-wrapper .content .card + .card {
  /* min-height: calc(100vh - 300px); */
  margin-bottom: 12px;
}

.content-wrapper .content .card.card-content + .card.card-details .card-body {
  border-radius: 8px;
}

.content-wrapper
  .content
  .card.card-content
  + .card.card-details
  .card-body
  .card-table
  .card-body {
  border-radius: 0;
}

.group-listing .right-block .usergroup-table .card.card-table {
  margin: 0;
}

.group-listing .right-block .usergroup-table .card.card-table .card-body {
  border-radius: 0;
}

.group-listing
  .right-block
  .usergroup-table
  .card.card-table
  .card-body
  .table-section {
  margin: 0;
}

.group-listing
  .right-block
  .usergroup-table
  .card.card-table
  .card-body
  .table-section
  .MuiToolbar-root.MuiToolbar-regular {
  margin: 0;
}

.content-wrapper
  .content
  .card.card-accordion.tree-view
  .accordion-body
  .fieldset {
  padding: 0;
  max-width: 350px;
}

.content-wrapper
  .content
  .card.card-accordion.tree-view
  .accordion-body
  .fieldset
  select {
  appearance: auto;
  height: 32px;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .btn.btn-primary {
  width: 100% !important;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .item {
  width: 100%;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 16px 15px 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #edeef1;
}

.headerContents .col-4 .row.to-right > .item {
  width: auto;
}

.under-contruction {
  min-height: calc(100vh - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.under-contruction .sub {
  margin: 0;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
}

/* ////////////////////// */

.treeContainer {
  width: 30%;
  padding: 16px;
}

.dataGrid {
  width: 70%;
  padding: 0;
  border-left: 1px solid #ebebeb;
}

.maxWidth {
  width: 100%;
}

.flexDisplay {
  display: flex;
}

.fullbutton {
  width: calc(100% - 40px);
  margin: 10px !important;
}

.treeIcon {
  font-size: 20px !important;
}

.nodataIcon {
  font-size: 100px;
}

.defaultSubHeader {
  text-align: left;
  font-weight: 700;
  padding-left: 0px;
  font-size: 14px;
  line-height: 1.6;
}

.subItemsContainer {
  width: 100%;
  margin: 0;
  min-height: calc(100vh - 256px);
  background: white;
  padding: 0;
}

.treeitemContainer {
  line-height: 0;
  margin: auto 0px;
  border-right: 1px solid #8080804f;
}

.subItemsHeaderBar {
  display: flex;
  width: 80%;
  padding-left: 20px;
}

.rightButtonContainer {
  margin: 0px 10px;
}

.tabButtonContainer {
  margin: 5px 15px;
}

.headerbuttonContainer {
  display: flex;
}

.formContainer {
  padding: 12px 16px;
}

.halfRow {
  width: 380px;
  padding: 0 14px 12px;
  max-width: 100%;
}

.defaultMargin {
  margin: 0px 16px !important;
}

.table-cont-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

div.iconbutton button svg {
  position: relative !important;
  left: 1px !important;
  /* top: 2px!important; */
  font-size: 14px !important;
  width: 32px !important;
}

.icon-only {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  height: 32px;
  cursor: pointer;
  width: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #222222;
  text-transform: capitalize;
  border-color: #ccc !important;
  /* min-width: 72px; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(0, 0, 0, 0.06) 100%
  );
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chipClose {
  top: 5px;
  font-size: 8px;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.tableButtonIcons {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip-label {
  top: 2px;
  position: relative;
  font-size: 12px;
}

.filterChipsContainer {
  padding: 20px 0px 0px 0px;
  margin-top: 10px !important;
  border-top: 1px solid #80808036;
}

.fiterInfo {
  padding: 0px 0px 0px 0px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #555555;
}

.textButton {
  border: none !important;
  cursor: pointer;
  background: none !important;
  font-size: 12px !important;
}

.filterchips {
  border-radius: 0px;
}

/* //////////////////////////// */

.min_width {
  min-width: initial !important;
  padding: 0 8px !important;
}

.content-wrapper .content .card.card-accordion.card-settings {
  margin: 0 16px 12px;
  width: auto;
  min-height: auto;
}

.content-wrapper .content .card.card-accordion.card-settings .accordion-item {
  /* max-height: 350px; */
  display: flex;
  flex-direction: column;
}
.content-wrapper .content .card.card-accordion.card-settings .card-body {
  overflow: visible;
}
.content-wrapper
  .content
  .card.card-accordion.card-settings
  .accordion-item
  .accordion-header {
  flex-shrink: 0;
}

.content-wrapper
  .content
  .card.card-accordion.card-settings
  .accordion-item
  .accordion-collapse {
  position: relative;
  /* overflow-y: auto; */
}

.content-wrapper
  .content
  .card.card-accordion.card-settings
  .accordion-body
  .fieldset {
  /* flex: 0 0 380px; */
  max-width: 100%;
  width: 380px;
}

.fieldset .control-label .tooltip-icon {
  position: relative;
  top: -1px;
  margin-left: 6px;
}

.btn.btn-transparent {
  width: 32px;
  margin: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button.disabled {
  cursor: default;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .MuiTablePagination-input
  + .Component-paginationCaption-20 {
  display: none;
}

.card.card-table
  .card-body
  .table-section
  table
  tfoot
  .MuiToolbar-regular
  .Component-paginationCaption-20 {
  display: block;
  position: absolute;
  right: 120px;
  display: none;
}

.seach-con fieldset.input-group input {
  padding: 0;
  height: 32px !important;
  line-height: 32px !important;
  min-height: 32px !important;
}

.seach-con fieldset.input-group button {
  padding: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card.card-table .card-body .table-section table tbody tr td button {
  padding: 0 12px !important;
}

.grid-content {
  border-bottom: 1px solid #ebebeb;
  width: 100% !important;
  margin: 0 !important;
}

.grid-content .subItemsHeaderBar {
  padding: 12px 16px !important;
}

.grid-content .headerbuttonContainer {
  padding: 0 16px !important;
}

.grid-content .subItemsHeaderBar p.defaultSubHeader {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}

.treeContainer .btn.btn-secondary {
  margin: 0 0 12px !important;
  width: 100% !important;
}

.treeContainer .btn.btn-secondary {
  margin: 0 0 12px !important;
  width: 100% !important;
}

.treeview-list li > .MuiTreeItem-content {
  text-align: left;
}

.treeview-list li .MuiTreeItem-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  padding: 4px 16px;
  border-radius: 4px;
}

.treeview-list li ul {
  padding-top: 4px;
}

.treeview-list li .MuiTreeItem-content .MuiTreeItem-iconContainer {
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.treeview-list li .MuiTreeItem-label:before {
  content: "";
  position: absolute;
  top: 14px;
  width: 23px;
  left: -18px;
  height: 1px;
  border-top: 1px dashed rgba(0, 0, 0, 0.4);
}

.treeview-list li .MuiTreeItem-content .MuiTreeItem-iconContainer .overlay {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 16px;
  color: #fff;
  line-height: 14px;
  position: relative;
  text-align: center;
  font-weight: 300;
}

.treeview-list li .MuiTreeItem-content .MuiTreeItem-iconContainer .dot {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  position: relative;
}

ul.MuiMenu-list {
  padding: 0.2rem 0 !important;
  box-shadow: none;
  border-radius: 8px;
  max-height: 280px;
  /* max-width: 385px; */
  /* overflow-y: auto; */
}

ul.MuiMenu-list li.MuiListItem-button {
  font-weight: normal !important;
  font-size: 0.8rem !important;
  line-height: 1.2rem !important;
  display: flex !important;
  align-items: center !important;
  color: #222222 !important;
  padding: 0.3rem 0.8rem !important;
  height: initial !important;
  min-height: initial !important;
}

ul.MuiMenu-list li.MuiListItem-button > p {
  margin: 0;
}

.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
  padding: 0 !important;
  background: transparent !important;
}

.fieldset .input-group.muiselect .form-control {
  min-height: 34px;
}

.fieldset .input-group.muiselect .form-control:before,
.fieldset .input-group.muiselect .form-control:after {
  border-bottom: transparent !important;
}

ul.MuiMenu-list li {
  font-size: 0.8rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  color: #222222;
  justify-content: flex-start;
  padding: 0.3rem 0.8rem;
  white-space: normal;
  max-width: 385px;
}

ul.MuiMenu-list li > span.MuiCheckbox-root {
  padding: 0;
  margin-right: 8px;
}

ul.MuiMenu-list li > span.MuiCheckbox-root svg {
  font-size: 15px;
}

.textareastyle {
  resize: none;
  height: 64px !important;
}

.btnseccolor {
  color: #222 !important;
}

.noPadding {
  padding: 0px !important;
}

.fieldset .input-group.muiselect .form-control .MuiChip-filledDefault {
  min-height: 20px !important;
  height: auto !important;
  font-size: 12px;
  line-height: 14px;
  margin-right: 6px;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
}

.fieldset .input-group.muiselect .form-control span.MuiChip-label {
  padding: 0;
  line-height: normal;
  white-space: normal;
}

.MuiModal-root .MuiDialog-container .MuiDialog-paperScrollPaper {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.MuiModal-root .MuiDialog-container .MuiTypography-h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  padding: 16px !important;
  box-shadow: inset 0px -1px 0px #edeef1;
  border-radius: 8px 8px 0px 0px;
}

.MuiModal-root .MuiDialog-container .css-ypiqx9-MuiDialogContent-root {
  padding: 16px;
}

.MuiModal-root .MuiDialog-container .css-ypiqx9-MuiDialogContent-root p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 0;
  word-break: break-all;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiDialogActions-root.MuiDialogActions-spacing
  .btn
  + .btn.btn-primary {
  width: auto !important;
}

.fieldset .input-group > .rmdp-container {
  width: 100%;
}

.fieldset .input-group > .icon {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.custom-calendar {
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2); */
}
.custom-calendar + div {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
}

.custom-calendar .rmdp-calendar {
  padding: 8px;
}

.custom-calendar .rmdp-header {
  margin: 0;
  height: 28px;
  line-height: 28px;
}

.custom-calendar .rmdp-day-picker {
  padding: 0;
  margin-top: 4px;
}

.custom-calendar .rmdp-header .rmdp-arrow-container {
  height: 28px;
  align-items: center;
  width: 28px;
}

.custom-calendar .rmdp-header .rmdp-arrow-container i.rmdp-arrow {
  margin: 0;
  border-color: #333333;
  width: 6px;
  height: 6px;
}

.custom-calendar .rmdp-header .rmdp-arrow-container:hover {
  background: transparent;
  border-color: #fff;
  box-shadow: none;
}

.custom-calendar .rmdp-header .rmdp-arrow-container:hover i.rmdp-arrow {
}

.custom-calendar .rmdp-day-picker .rmdp-week-day {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  width: 38px;
  height: 28px;
}

.custom-calendar .rmdp-day-picker .rmdp-day {
  width: 38px;
  height: 30px;
}

.custom-calendar .rmdp-day-picker .rmdp-week {
  margin-bottom: 4px;
}

.custom-calendar .rmdp-day-picker .rmdp-day span {
  width: 24px;
  height: 24px;
  left: 7px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-today span,
.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-today span:hover {
  color: #0074d9 !important;
  border-radius: 4px;
  border-color: #0074d9;
  background: transparent;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-selected span {
  color: #fff;
}

.fieldset .input-group > .rmdp-container > .custom-calendar {
  box-shadow: none;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-today.rmdp-selected span {
  background: #0074d9;
  color: #fff !important;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-today.rmdp-selected span {
  background: #0074d9;
  color: #fff !important;
}

.custom-calendar .rmdp-month-picker .rmdp-day.rmdp-today span,
.custom-calendar .rmdp-month-picker .rmdp-day.rmdp-today span:hover {
  color: #0074d9 !important;
  border-radius: 4px;
  border-color: #0074d9;
  background: transparent;
}

.custom-calendar .rmdp-month-picker .rmdp-day span {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #333333;
}

.custom-calendar .rmdp-month-picker .rmdp-day.rmdp-selected span,
.custom-calendar .rmdp-month-picker .rmdp-day.rmdp-selected.rmdp-today span {
  color: #fff !important;
  background-color: #0074d9;
}

.custom-calendar .rmdp-year-picker .rmdp-day.rmdp-today span,
.custom-calendar .rmdp-year-picker .rmdp-day.rmdp-today span:hover {
  color: #0074d9 !important;
  border-radius: 4px;
  border-color: #0074d9;
  background: transparent;
}

.custom-calendar .rmdp-year-picker .rmdp-day span {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #333333;
}

.custom-calendar .rmdp-year-picker .rmdp-day.rmdp-selected span,
.custom-calendar .rmdp-year-picker .rmdp-day.rmdp-selected.rmdp-today span {
  color: #fff !important;
  background-color: #0074d9;
}

.fieldset .input-group > .rmdp-container .form-control {
  cursor: pointer;
}

.boxed-wrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.custom-chips {
  padding: 0px 10px;
  border: 1px solid;
  border-radius: 4px;
  height: 24px;
  margin-right: 0px;
  display: flex;
  background: none !important;
  margin: 2px 0 2px 8px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 34px;
  padding: 0;
  background: transparent !important;
  -webkit-text-fill-color: unset !important;
}

.MuiSelect-nativeInput {
  padding: 0;
  bottom: 0px !important;
}

.card.card-table .card-body .table-section .MuiToolbar-regular .form-control {
  /* padding: 0 !important; */
  background: transparent !important;
  height: 32px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  background: transparent !important;
  top: -5px;
  border: none !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline
  legend.css-1a1ihhj {
  display: none;
}

.card.card-table
  .card-body
  .table-section
  .MuiToolbar-regular
  .form-control
  > svg.MuiSvgIcon-root {
  top: calc(50% - 13px) !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left;
  padding-left: 14px;
}

.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input
  .MuiBox-root.css-yi3mkw {
  background: transparent !important;
}

.card.card-table .card-body .table-section .toolbar_listing {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 0px;
}

.card.card-table
  .card-body
  .table-section
  .toolbar_listing
  .toolbar-container
  .toolbar-serchblock {
  padding: 0 16px;
  margin-bottom: 0px;
}

.card.card-table
  .card-body
  .table-section
  .toolbar_listing
  .toolbar-container
  .filterChipsContainer {
  padding: 12px 16px;
  margin: 0 !important;
  border-top: 1px solid #edeef1;
  border-bottom: 1px solid #edeef1;
}

.card.card-table
  .card-body
  .table-section
  .toolbar_listing
  .toolbar-container
  .toolbar-serchblock
  .MuiGrid-root {
  margin: 0;
}

.card.card-table
  .card-body
  .table-section
  .toolbar_listing
  .toolbar-container
  .filterChipsContainer
  .MuiGrid-root {
  margin: 0 !important;
}

.btn.btnsml {
  height: 24px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
}

.fieldset.selectbox-fieldset .input-group fieldset {
  background: transparent !important;
  top: -5px;
  border: none !important;
}

.fieldset.selectbox-fieldset .MuiSelect-select {
  padding: 0;
  background: transparent !important;
  text-align: left;
}

.quickcreate-form .MuiGrid-item {
  padding-top: 12px;
}

.quickcreate-form .MuiGrid-item .fieldset {
  margin-bottom: 0 !important;
}

.fieldset.textarea-fieldset textarea.form-control {
  min-height: 68px;
  height: 68px;
}

.quickcreate-form .MuiGrid-item .fieldset.checkbox-box .input-group {
  min-height: 34px;
  display: flex;
  align-items: center;
}

.quickcreate-form
  .MuiGrid-item
  .fieldset.checkbox-box
  .input-group
  label.control-label {
  margin: 0;
  margin-left: 6px;
}
.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .row
  > .row-title {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: left;
  padding: 0 14px;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .row
  > .row-title
  h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 16px;
}
.card.card-accordion {
}

.card.card-accordion .details-accordion {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.card.card-accordion .details-accordion .MuiAccordionSummary-content {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
}

.card.card-accordion
  .details-accordion
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: inherit !important;
  width: 100%;
  text-align: left;
  padding: 10px 16px;
  background: #fafafa;
  border-bottom: 1px solid #edeef1;
}

.card.card-accordion .details-accordion .MuiCollapse-vertical {
}

.card.card-accordion
  .details-accordion
  .MuiCollapse-vertical
  .MuiAccordionDetails-root {
  padding: 16px;
}

.card.card-accordion
  .details-accordion
  .MuiCollapse-vertical
  .MuiAccordionDetails-root
  > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
}

.card.card-accordion .details-accordion:before {
  background: transparent;
}

.card.card-accordion
  .details-accordion
  .MuiCollapse-vertical
  .MuiAccordionDetails-root
  > .MuiGrid-container {
  width: 100%;
  margin: 0 -16px;
}

.card.card-accordion
  .details-accordion
  .MuiCollapse-vertical
  .MuiAccordionDetails-root
  > .MuiGrid-container
  .MuiGrid-item {
  padding: 0 16px 12px;
}

.card.card-accordion
  .details-accordion
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  position: relative;
  top: 1px;
}

/* media query */

@media (max-width: 2010px) {
  .content-wrapper
    .content
    .card.card-accordion.card-settings
    .accordion-body
    .fieldset,
  .halfRow,
  .card.card-accordion .accordion .accordion-item .accordion-body .fieldset,
  .card.card-accordion
    .details-accordion
    .MuiCollapse-vertical
    .MuiAccordionDetails-root
    > .MuiGrid-container
    .MuiGrid-item {
    max-width: calc(100% / 4);
    width: calc(100% / 4);
  }
}

@media (max-width: 1625px) {
  .content-wrapper
    .content
    .card.card-accordion.card-settings
    .accordion-body
    .fieldset,
  .halfRow,
  .card.card-accordion .accordion .accordion-item .accordion-body .fieldset,
  .card.card-accordion
    .details-accordion
    .MuiCollapse-vertical
    .MuiAccordionDetails-root
    > .MuiGrid-container
    .MuiGrid-item {
    max-width: calc(100% / 3);
    width: calc(100% / 3);
  }
}

@media (max-width: 1250px) {
  .content-wrapper
    .content
    .card.card-accordion.card-settings
    .accordion-body
    .fieldset,
  .halfRow,
  .card.card-accordion .accordion .accordion-item .accordion-body .fieldset,
  .card.card-accordion
    .details-accordion
    .MuiCollapse-vertical
    .MuiAccordionDetails-root
    > .MuiGrid-container
    .MuiGrid-item {
    max-width: calc(100% / 2);
    width: calc(100% / 2);
  }
}

@media (max-width: 870px) {
  .content-wrapper
    .content
    .card.card-accordion.card-settings
    .accordion-body
    .fieldset,
  .halfRow,
  .card.card-accordion .accordion .accordion-item .accordion-body .fieldset,
  .card.card-accordion
    .details-accordion
    .MuiCollapse-vertical
    .MuiAccordionDetails-root
    > .MuiGrid-container
    .MuiGrid-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}
.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px !important;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.MuiIconButton-root:hover {
  background: none !important;
}

.fieldset .control-label .icon-padding {
  padding: 0 4px;
  position: relative;
  top: 2px;
  cursor: pointer;
}
.boxed-wrapper > .row {
  margin: 0;
}
.boxed-wrapper > .row {
  margin: 0;
}
.card.card-accordion.card-userdetails {
  margin: 0 0 12px !important;
}

.card.card-accordion.card-userdetails .card-body {
}

.card.card-accordion.card-userdetails
  .card-body
  .accordion
  .accordion-item
  .accordion-body {
  /* max-height: calc(100vh - 325px) !important;
  min-height: calc(100vh - 325px) !important; */
}

.card.card-accordion.card-userdetails > .card-body {
  min-height: calc(100vh - 282px);
  overflow: hidden;
}

.card.card-details-head
  .d-flex.head-details
  .flex-box-left
  .details
  .sub
  > .icon-padding {
  position: relative;
  top: 2px;
}
.tag.fill {
  color: #fff;
}
.card.card-details-head .d-flex.head-details.webform-details .details .sub {
  font-size: 12px;
  line-height: 16px;
}
.card.card-details-head .d-flex.head-details.webform-details {
  min-height: 96px;
}
.card.card-details-head.roles-head .card-body {
  padding: 18px 16px !important;
  min-height: 96px;
  display: flex;
  align-items: center;
}

.card.card-details-head.roles-head .card-body .row {
  align-items: center;
}
.card.card-details-head.listing-head {
  margin: 0 16px 12px !important;
}

.card.card-details-head.listing-head + .card.card-table {
  margin: 0 16px 12px !important;
  flex: 1 1;
}
.card.card-accordion.card-userdetails
  .card-body
  .accordion
  .accordion-item
  .accordion-body
  .fullwidth
  button {
  margin: 0 auto;
}

.universal-searchbox {
  margin: 0;
}

.universal-searchbox .custom-select.form-control {
  height: 31px !important;
  /* background-image: none; */
  background: transparent;
  /* appearance: auto; */
  padding: 0 8px;
  min-width: 80px;
  border-right: 1px solid #e0e2e7;
  cursor: pointer;
  border-bottom: 1px solid #e0e2e7;
  /* padding-right: 27px; */
  z-index: 2;
  position: relative;
}

.universal-searchbox .custom-select.form-control:focus {
  box-shadow: none;
  outline: none;
}

.universal-searchbox input.form-control {
  min-width: 250px;
}
.universal-searchbox .selectbox {
  position: relative;
  background: #f8fafb;
  max-height: 30px;
}

.universal-searchbox .selectbox > svg {
  position: absolute;
  right: 8px;
  top: 11px;
  z-index: 0;
}
.btn.btn-tertiary {
  border-style: solid;
  border-width: 1px;
  margin: 0;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  min-width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.card.card-table .card-body .table-section .MuiPaper-elevation2 > div {
  overflow-y: hidden;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .fieldset.checkbox-box
  .input-group {
  line-height: 34px;
  display: flex;
  align-items: center;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .fieldset.checkbox-box
  .input-group
  > label.control-label {
  margin: 0;
  margin-left: 6px;
  line-height: 34px;
  cursor: pointer;
}

.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .fieldset.checkbox-box
  .input-group
  > input {
  position: relative;
  top: -1px;
  cursor: pointer;
}
.arrow-steps .step.current select option {
  color: #222 !important;
  font-size: 12px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 0px;
}
.assigndialog.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
  max-width: 80% !important;
  width: 1200px !important;
  /* min-height: 624px; */
  max-height: calc(100vh - 60px);
}
.assigndialog.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  tfoot.MuiTableFooter-root {
  /* display: none !important; */
}

.assigndialog.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .toolbar_listing {
  display: none;
}
.assigndialog.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .col-4
  > .seach-con {
  display: none;
}
.fieldset .input-group.muiselect .form-control.disabled {
  border-color: #edeef1 !important;
  background-color: #fafafa !important;
}
.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  text-align: left;
}
.fieldset
  .input-group.muiselect
  .form-control
  .MuiSelect-select.MuiSelect-outlined
  > .MuiBox-root {
  padding: 4px 16px 4px 0;
}
.chipClose svg {
  font-size: 12px;
}

.backdrop-popup {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.backdrop-popup .backdrop-bg {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.btn-blockNew {
  margin-top: 16px !important;
}

.btn-blockNew .smlbuttonContainer .item {
  margin-left: 8px;
}
.MuiGrid-spacing-xs-2.btn-blockNew {
  margin-bottom: 8px !important;
}
.assigndialog.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .card {
  box-shadow: none;
}
.seemore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px 0 4px;
}

.seemore a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
}
.detailBg.inner-listdetails
  .card-details-section
  > .card.card-accordion
  .card-body {
  overflow: visible;
}
.reset-modal .MuiDialog-paperScrollPaper > .MuiDialogContent-root {
  padding: 16px;
}

.reset-modal .MuiDialog-paperScrollPaper > .MuiDialogContent-root p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 0;
}
.input-group.muiselect .MuiSelect-select.MuiSelect-outlined {
  padding: 0 !important;
  background: transparent !important;
  text-align: left;
  padding-right: 30px !important;
  /* -webkit-text-fill-color: #222; */
}
.count-style {
  line-height: 40px;
  margin: 0 16px !important;
  font-weight: normal;
  font-size: 14px !important;
  color: #555555;
}
ul.MuiPagination-ul li {
  margin: 0 4px;
}

ul.MuiPagination-ul li button {
  width: auto !important;
  padding: 0 4px !important;
  opacity: 1 !important;
}

ul.MuiPagination-ul li button svg {
  width: 18px;
  font-size: 18px;
}

ul.MuiPagination-ul li button.Mui-selected {
  border: none !important;
  background: transparent !important;
  /* text-decoration: underline; */
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #0077be !important;
  border: 1px solid #0077be !important;
}
ul.MuiPagination-ul
  li
  button.MuiPaginationItem-circular.MuiPaginationItem-page {
  /* border: none !important; */
  background: transparent !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  min-width: 24px !important;
  padding: 0px 6px !important;
}

@media (max-width: 1200px) {
  .twocolumnwidth .MuiDialog-container .MuiPaper-elevation24 {
    left: 30px;
  }
}
.MuiSnackbar-root .MuiAlert-action {
  padding-top: 0;
  padding-right: 8px;
}
.MuiSnackbar-root .MuiAlert-message {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.MuiSnackbar-root {
  background: #fff;
  top: 54px !important;
  right: 16px !important;
  border-radius: 12px;
}
.card.card-table .card-body .table-section table thead tr th > div {
  position: relative;
}
.reset-modal.removeuser.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24 {
  max-width: 412px;
}
.reset-modal.removeuser.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .MuiDialogActions-spacing {
  padding: 8px 16px;
  border-top: 1px solid #edeef1;
}
.noRecords-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stretch_display {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  padding: 10% 0;
}

.noRecords-block p.text {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #888888 !important;
  margin: 0 auto !important;
  width: 100% !important;
  text-align: center !important;
  max-width: 45%;
}

.noRecords-block .img-box {
  margin-bottom: 14px;
}
.detailBg.inner-listdetails
  .card-details-section
  > .card.card-accordion
  .card-body
  .table-section {
  margin-top: 0;
  /* padding: 16px 16px 4px; */
}
/* .detailBg.inner-listdetails .card-details-section > .card.card-accordion .card-body {
  box-shadow: none;
  border: none;
} */

/* .card.card-table .card-body .table-section table thead tr th {
  border-top: 1px solid #edf0f4;
}

.card.card-table .card-body .table-section table tbody tr:last-child td {
  border-bottom: 1px solid #edf0f4 !important;
} */
.assigndialog .stretch_display {
  padding: 7% 0;
  text-align: center;
}
.card.card-table.tab-table-container > .card-body > .table-section {
  /* margin-top: 0 !important; */
}
.card-body.margin-0 > .table-section {
  margin-top: 0 !important;
}
.empty-content .fullwidth > img {
  margin-bottom: 8px;
}
.universal-searchbox .selectbox .input-group.muiselect .form-control {
  min-width: 130px;
  max-height: 31px !important;
  min-height: 31px !important;
  background: #f8fafb;
  border-radius: 4px 0 0 4px;
}
.card-details-section.marginal_zone
  .card.card-table.tab-table-container
  .card-body
  > .table-section {
  margin-top: 0;
}
.rmdp-down i {
  margin-top: 5px;
}

.rmdp-time-picker div input {
  border: none;
  flex-grow: 1;
  font-size: 12px;
  padding: 2px 0px;
  text-align: center;
  width: 30px !important;
}

.rmdp-arrow-container {
  border-radius: 4px;
}
.fieldset .input-group .form-control li.MuiMenuItem-root {
  font-weight: 400;
  font-size: 13px;
  color: #222;
  background: transparent;
  -webkit-text-fill-color: initial !important;
  padding: 0;
  cursor: default;
}
.fieldset
  .input-group
  .form-control
  li.MuiMenuItem-root.MuiMenuItem-gutters
  span {
  background: transparent !important;
}
.fieldset.textarea-fieldset textarea.form-control {
  line-height: normal;
  padding: 8px;
}
.fieldset.textarea-fieldset textarea.form-control::placeholder {
  line-height: normal;
}
.rmdp-time-picker {
  /* flex-direction: column;
  top: 0;
  height: 100%;
  border-bottom: 1px solid #cfd8e2;
  min-width: initial !important;
  background: #fff; */
}

.custom-calendar > .rmdp-top-class + div {
  /* border-bottom: 1px solid #cfd8e2;
  position: absolute;
  right: -110px;
  max-width: 110px !important;
  min-width: 110px !important;
  top: 0;
  height: 100%;
  border-bottom: 1px solid #cfd8e2;
  background: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%); */
}

.rmdp-time-picker > div {
  /* flex-direction: revert; */
}

.rmdp-time-picker div input {
  /* width: 50px; */
}

.rmdp-time-picker .dvdr {
  /* transform: rotate(90deg);
  position: relative;
  margin-top: 0; */
}
.header ul.nav-right .notification.timezone > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: #f8f8f8;
  color: #222222;
}
.columnlayout
  .content.conversation-layout.calendar-layout
  .left-block
  .timezone
  a.dropdown-item
  > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.columnlayout
  .content.conversation-layout.calendar-layout
  .left-block
  .timezone
  .item
  > div,
.columnlayout
  .content.conversation-layout.calendar-layout
  .left-block
  .timezone
  a.dropdown-item {
  width: 100%;
  padding: 0;
}
.activity-twocolumn.MuiModal-root
  .MuiDialog-container
  .MuiPaper-elevation24
  .activity-dialogcontent-loader
  > div
  > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.universal-searchbox .selectbox li {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #222222;
  background: transparent !important;
}
.universal-searchbox .selectbox .form-control fieldset {
  border: none;
}
.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-disabled span {
  color: #c4c4c4 !important;
}
.rmdp-ym .rmdp-day span {
  border-radius: 4px;
  padding: 2px 0;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: rgba(0, 119, 190, 0.06);
  color: #333;
}
.rmdp-time-picker div input:focus {
  border: none;
  outline: none;
}
.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-range.start {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-range.end {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-range span {
  color: #fff !important;
}

.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-range {
  box-shadow: none;
}

.rmdp-day.rmdp-range span {
  background: #0074d9;
}
.custom-calendar .rmdp-day-picker .rmdp-day.rmdp-today.rmdp-range span {
  color: #fff !important;
}
.rmdp-ym .rmdp-day span {
  border-radius: 4px;
}
.page_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.universal-searchbox .custom-select.form-control > div {
  padding-left: 0;
}
.row.row_flexb {
  align-items: flex-end;
}
.card.card-accordion
  .accordion
  .accordion-item
  .accordion-body
  .fieldset_bottom_btn {
  padding: 0 14px 14px;
}

.fieldset_bottom_btn button.btn {
  margin-left: 0 !important;
}
.convert-list .MuiAutocomplete-inputRoot input {
  font-weight: normal;
  font-size: 13px;
  line-height: 34px;
  color: #222222;
  height: 34px;
  padding: 0 8px !important;
}

.convert-list .MuiAutocomplete-inputRoot button {
  position: relative;
  top: 2px;
}

.group-listing.empty-block {
  justify-content: center;
}

.group-listing.empty-block .stretch_display {
  width: 100%;
}
.card.card-table
  .card-body
  .table-section
  .MuiToolbar-regular
  .form-control
  button.btn.no-margin {
  background: transparent !important;
  /* top: 1px !important; */
}
.MuiSnackbar-root .MuiAlert-message span b {
  font-weight: 500;
}
@media (max-width: 1099px) {
  .MuiModal-root
    .MuiDialog-container
    .MuiPaper-elevation24
    form
    > div.quickcreate-form {
    max-height: calc(100vh - 185px) !important;
  }
}
.common_dialog_overlay:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
}

.common_dialog_overlay .dialog-big-title-container {
  border-radius: 8px 8px 0 0;
  padding: 16px !important;
  height: auto;
}

.common_dialog_overlay .temp-form-container {
  width: 408px;
  margin: 0;
  padding: 0;
}

.common_dialog_overlay .temp-form-container .fieldSpacing {
  padding: 0;
}

.common_dialog_overlay .temp-form-container .MuiGrid-spacing-xs-2 {
  margin: 0;
  width: 100%;
  padding: 16px;
}

.common_dialog_overlay .temp-form-container .MuiGrid-spacing-xs-2:last-child {
  border-top: 1px solid #edeef1;
}

.common_dialog_overlay
  .temp-form-container
  .MuiGrid-spacing-xs-2
  .smlbuttonContainer {
  margin-left: 8px;
}
.custom-calendar,
.custom-calendar + div {
  z-index: 9999 !important;
}

.custom-calendar:before,
.custom-calendar:after {
  z-index: 9999 !important;
}
.dropdown-menu a.dropdown-item.red {
  color: #ab1b1b;
}
.MuiAlert-message > div > b {
  font-weight: 500;
}
.input-group.muiselect .MuiSelect-select {
  -webkit-text-fill-color: unset !important;
}
.btn.btn-secondary:focus {
  background-color: unset;
  color: unset;
}
.card.card-table.blacklisting_stretch .stretch_display {
  min-height: calc(100vh - 290px) !important;
}
.login-wrapper
  .right-block
  .form-wrapper
  .card
  fieldset
  .input-group
  .btn.no-margin
  .icon {
  top: 15px;
  right: 8px;
  font-size: 16px;
}

.assign_selectmodal .quickcreate-form .MuiInputBase-formControl {max-height: 136px;overflow-x: hidden;overflow-y: auto;}
.fieldset .input-group.mandatory button.btn.no-margin:hover {
  background: transparent;
}
.fieldset .input-group.mandatory button.btn.no-margin:hover {background: transparent;}

.fieldset .input-group.muiselect .form-control .MuiChip-filledDefault svg.MuiSvgIcon-root {margin: 0 0 0 6px;width: 17px;height: 17px;}

.fieldset .input-group.muiselect .form-control .MuiChip-filledDefault {min-width: 24px;color: #0077BE;border-color: #0077BE;background: #F0F7FB !important;}
.fieldset .input-group > .rmdp-container + span.icon span.icon svg {position: relative;top: -2px;right: 3px;}
.assignee-block .activity-item .MuiFormControl-fullWidth > div span.MuiCircularProgress-root {
  right: 8px;
  position: relative;
}
.flex_Block {display: flex;align-items: center;position: relative;}

.flex_Block > .fieldset {padding-right: 0 !important;}

.flex_Block span.icon {width: 16px;height: 16px;position: relative;right: 24px;cursor: pointer;}

.flex_Block > .fieldset .mandatory.input-group  .input-group {display: flex;align-items: center;}

.flex_Block > .fieldset .mandatory.input-group button.btn.no-margin {top: 7px !important;right: 5px !important;}

.flex_Block > .fieldset .mandatory.input-group .input-group .form-control {width: 100%;}
.fieldset .input-group.muiselect .form-control .MuiChip-filledDefault +  input {font-size: 13px;padding: 5px 4px  !important;}
.assign_selectmodal .quickcreate-form .MuiAutocomplete-root .MuiOutlinedInput-root {
  border: 1px solid #00000042!important;
}
.fieldset .input-group.muiselect .MuiAutocomplete-root.form-control {
  max-height: 118px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 6px;
}
.fieldSpacing .flex_Block {display: block !important;}

.fieldSpacing .flex_Block .fieldset {width: 100%;}

.fieldSpacing .flex_Block > span.icon {top: 26px;position: absolute;right: 12px;}
.fieldSpacing .flex_Block > .fieldset .mandatory.input-group button.btn.no-margin {right: 9px !important;}
::-ms-reveal {
  display: none;
}
fieldset.input-group.currency_Field .fieldset.selectbox-fieldset {width: auto !important;max-width: initial !important;flex: unset !important;padding: 0 !important;}

fieldset.input-group.currency_Field .fieldset.selectbox-fieldset button.btn.no-margin {width: auto !important;background: transparent !important;}

fieldset.input-group.currency_Field .fieldset.selectbox-fieldset .form-control {min-height: 32px !important;max-height: 32px !important;min-width: 100px;}

fieldset.input-group.currency_Field > .form-control {border-radius: 0 4px 4px 0;border-left: none !important;min-width: auto !important;}

fieldset.input-group.currency_Field .fieldset.selectbox-fieldset 
> .input-group.muiselect {max-width: 100px;}
fieldset.input-group.currency_Field>.form-control::placeholder {
  font-weight: normal;
  font-size: 13px;
}
ul.MuiMenu-list li.common_width_list {
  max-width: 420px;
}
.fieldset.selectbox-fieldset.search_Multiselect .form-control {display: flex;align-items: center;padding-right: 0 !important;flex-wrap: wrap;}

.fieldset.selectbox-fieldset.search_Multiselect .form-control .MuiChip-filledDefault + input,.fieldset.selectbox-fieldset.search_Multiselect .form-control  input {padding: 0 !important;font-size: 13px !important;color: #222;padding-right: 55px !important;}

.fieldset.selectbox-fieldset.search_Multiselect .form-control input + span {right: 10px;position:relative;background:#fff;z-index:2;}



.fieldset.selectbox-fieldset.search_Multiselect .form-control  fieldset {padding-right: 0 !important;}

.fieldset.selectbox-fieldset.search_Multiselect .form-control  .MuiAutocomplete-endAdornment {}

.fieldset.selectbox-fieldset.search_Multiselect .form-control .MuiAutocomplete-endAdornment button.MuiAutocomplete-clearIndicator svg {font-size: 15px;position: relative;right: -2px;}

.fieldset.selectbox-fieldset.search_Multiselect .form-control .MuiOutlinedInput-root {padding-right: 42px !important;}
.MuiAutocomplete-endAdornment button.MuiAutocomplete-clearIndicator svg {
  font-size: 16px;
}
.fieldset.selectbox-fieldset.search_Multiselect .form-control .MuiChip-filledDefault + input::placeholder, .fieldset.selectbox-fieldset.search_Multiselect .form-control input::placeholder {font-size: 13px;}

.fieldset.selectbox-fieldset .input-group.muiselect input.MuiAutocomplete-input {font-size: 13px;min-height: 24px;}

.fieldset.selectbox-fieldset .input-group.muiselect input.MuiAutocomplete-input::placeholder {font-size: 13px;}
ul.MuiMenu-list li.filter_Menuitem {max-width: 290px;}
.quill {
  min-height: 150px;
}
.attachment_modal.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
  width: 800px;
}.attachment_modal.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
    width: 800px;
}
.dropzone_block {
  position: relative;
}

.dropzone_block .dropzone_detail {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0;
  z-index: 1;
  background: #F8FAFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dropzone_block .MuiDropzoneArea-root {
  min-height: 150px !important;
  border-radius: 4px;
  border-width: 1px;
  border-color: #B6B6B6;
  position: relative;
  z-index: 2;
  background: transparent;
}

.dropzone_block .MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
  display: none;
}

.attachment_modal .dropzone_block .dropzone_detail span.btn.btn-primary {
  width: auto !important;
}

.dropzone_block .dropzone_detail p.text_bold {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.dropzone_block .dropzone_detail p.text {
  margin-bottom: 12px;
}
.attachment_modal .table-section {
  margin-top: 16px;
}

.attachment_modal .table-section table.custom_Table {
  width: 100%;
}

.attachment_modal .table-section table.custom_Table thead th {
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  border-left: 1px solid #fff;
  text-align: left;
  border-bottom: none;
  text-transform: capitalize;
  background: #f0f7fb !important;
  min-width: 270px;
}
.attachment_modal .table-section table.custom_Table thead th:last-child {
  min-width: 60px !important;
  max-width: 60px;
}

.attachment_modal .table-section table.custom_Table tbody td {
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 24px;
  color: #222222 !important;
  padding: 4px 10px;
  border-left: 1px solid #edf0f4;
  border-bottom: 1px solid #edf0f4;
  text-align: left;
}

.attachment_modal .table-section table.custom_Table tbody td:last-child {
  text-align: center;
}

.attachment_modal .table-section table.custom_Table tbody td .fieldset {
  margin: 0;
}

.attachment_modal .table-section table.custom_Table tbody td .fieldset label.control-label {
  margin: 0;
}

.attachment_modal .table-section table.custom_Table tbody td .fieldset  .form-control {
  line-height: 24px;
  height: 24px;
  padding-right: 24px;
}

.attachment_modal .table-section table.custom_Table thead th:last-child,.attachment_modal .table-section table.custom_Table tbody td:last-child {
  border-right: 1px solid #edf0f4;
}
.attachment_modal .table-section table.custom_Table tbody td .fieldset  button.btn.no-margin {
  position: relative;
  top: 3px !important;
}
.attch_Block {
  padding: 0 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attch_Block a.control-label label {
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0077BE;
  cursor: pointer;
}

.attch_Block a.control-label label svg {
  width: 14px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.attch_Block p.control-label {
  margin-bottom: 0 !important;
}

.attachment_modal .table-section {
  width: 100%;
}

.attachment_modal.custom_Popup_Height .table-section {
  margin-top: 8px;
}
.fieldset.dataset .input-group .text.link_text {
    color: #0077BE;
    text-decoration: underline;
}
.attachment_modal.custom_Popup_Height .table-section .empty_Text {
  padding: 16px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #888;
  margin: 0;
}
.empty_Text {
  padding: 16px 0;
}

.empty_Text .text {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #888 !important;
  margin: 0;
}
.activity-singlecolumn.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .activity-wrap.row {
  border: none;
}

.activity-singlecolumn.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .activity-wrap.row .activity-form {
  padding-top: 0;
}
.attachment_modal .table-section table.custom_Table tbody td p.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 0;
  word-break: break-all;
}
.css-hke119.Mui-disabled {
  opacity: 1 !important;
}

.fieldset .input-group.muiselect .form-control span.MuiChip-label {
  text-align: left !important;
}
.MuiAutocomplete-listbox li {
  font-size: .8rem !important;
    line-height: 1.2rem !important;
    display: flex !important;
    align-items: center !important;
    color: #222 !important;
    justify-content: flex-start !important;
    padding: 0.3rem 0.8rem !important;
    white-space: normal !important;
    max-width: 385px !important;
}
.MuiAutocomplete-listbox {
  padding: 0.2rem 0!important;
  box-shadow: none !important;
  border-radius: 8px !important;
  max-height: 280px !important;
  z-index: 1 !important;
}
.fieldset.selectbox-fieldset.search_Multiselect .form-control input {
  min-width: 30px !important;
  padding: 0 !important;
}

.fieldset.selectbox-fieldset.search_Multiselect .form-control .MuiChip-filledDefault+input {
  min-width: 30px !important;
  max-width: 60px;
  padding: 0 !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-dzseci .MuiAutocomplete-inputRoot {
  padding-right: 52px !important;
}
/* .MuiAutocomplete-popper{
  z-index: 2 !important;
} */
.breadcrumb-wrapper {
  padding: 0 16px;
  /* z-index: 3; */
  /* background: linear-gradient(180deg,#e1eeff,#d3e2f3); */
}
.MuiGrid-container{
  /* z-index: 3; */
}
.card.card-content.card-content-head {
  z-index: 3;
}
.fieldset.selectbox-fieldset .input-group.muiselect input.MuiAutocomplete-input::placeholder {
  color: #888 !important;
  opacity:1 !important;
  /* z-index: 3; */
}
.note-drawer .note-popup .note-list .item .details.details_margin audio {
  height: 32px;
  transform: scale(0.8);
  transform-origin: left;
}